import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.web';

function initializeGoogleAnalytics() {
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  window.gtag = gtag;
  gtag('js', new Date());
  gtag('config', 'G-9YS8NHLLTW');

  // Load the script
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-9YS8NHLLTW';
  script.async = true;
  document.head.appendChild(script);
}

console.log('Index.js is executing - version 14');
console.log('Imported App component:', App);
console.log('App component type:', typeof App);
console.log('App component prototype:', Object.getPrototypeOf(App));

// Initialize Google Analytics
initializeGoogleAnalytics();

const rootElement = document.getElementById('root');
console.log('Root element:', rootElement);

if (rootElement) {
  try {
    console.log('Creating root');
    const root = ReactDOM.createRoot(rootElement);
    console.log('Root created');

    console.log('About to render App component');
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
    console.log('Root rendered - version 12');
  } catch (error) {
    console.error('Error rendering App:', error);
  }
} else {
  console.error('Root element not found');
}

console.log('End of index.js');