import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database'; // Import Realtime Database
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBF0Dz-NKOM1c1yfRfV7sie2tRn2a6nHF8",
  authDomain: "furgenai.firebaseapp.com",
  databaseURL: "https://furgenai-default-rtdb.firebaseio.com",
  projectId: "furgenai",
  storageBucket: "furgenai.appspot.com",
  messagingSenderId: "1086361808296",
  appId: "1:1086361808296:web:8fa9b49ea3800954f5419d",
  measurementId: "G-9YS8NHLLTW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app); // Initialize Realtime Database
const analytics = getAnalytics(app); // Initialize Firebase Analytics

export { auth, db, rtdb, analytics }; // Export Realtime Database

