import React, { createContext, useState, useContext, useEffect } from 'react';
import { Dimensions } from 'react-native';

const BackgroundImageContext = createContext();

export const useBackgroundImage = () => useContext(BackgroundImageContext);

export const BackgroundImageProvider = ({ children }) => {
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [isMobile, setIsMobile] = useState(Dimensions.get('window').width <= 768);

  useEffect(() => {
    const handleDimensionsChange = ({ window }) => {
      setIsMobile(window.width <= 768);
    };

    Dimensions.addEventListener('change', handleDimensionsChange);

    return () => {
      Dimensions.removeEventListener('change', handleDimensionsChange);
    };
  }, []);

  const updateBackgroundImages = (images) => {
    setBackgroundImages(images);
  };

  const contextValue = {
    backgroundImages,
    updateBackgroundImages,
    isMobile,
  };

  return (
    <BackgroundImageContext.Provider value={contextValue}>
      {children}
    </BackgroundImageContext.Provider>
  );
};